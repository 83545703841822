import React from 'react';

// My Routes
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));
const Orbiqon = React.lazy(() => import('./pages/Orbiqon/index'));
const Services = React.lazy(() => import('./pages/Orbiqon/services'));
const AboutUs = React.lazy(() => import('./pages/Orbiqon/about'));
const ContactUs = React.lazy(() => import('./pages/Orbiqon/contact'));
// const Team = React.lazy(() => import('./pages/Orbiqon/team'));
const Awards = React.lazy(() => import('./pages/Orbiqon/awards'));
const Profile = React.lazy(() => import('./pages/Orbiqon/profile'));
const Career = React.lazy(() => import('./pages/Orbiqon/career'));
const Blog = React.lazy(() => import('./pages/Orbiqon/Blog/Index'));
const SinglePost = React.lazy(() => import('./pages/Orbiqon/Blog/SinglePost'));
const Portfolio = React.lazy(() => import('./pages/Orbiqon/Portfolio/Index'));
const SingleProject = React.lazy(() =>
  import('./pages/Orbiqon/Portfolio/Project'),
);

const routes = [
  { path: '/', component: Orbiqon, isTopbarDark: true },
  { path: '/services', component: Services, isTopbarDark: true },
  { path: '/about-us', component: AboutUs, isTopbarDark: true },
  { path: '/contact-us', component: ContactUs, isTopbarDark: true },
  // { path: '/team', component: Team, isTopbarDark: true },
  { path: '/awards', component: Awards, isTopbarDark: true },
  { path: '/careers', component: Career, isTopbarDark: true },
  { path: '/blog', component: Blog, isTopbarDark: true },
  { path: '/blog/:id', component: SinglePost, isTopbarDark: true },
  { path: '/portfolio', component: Portfolio, isTopbarDark: true },
  { path: '/portfolio/:id', component: SingleProject, isTopbarDark: true },
  {
    path: [
      '/t/shazim-khan',
      '/t/yasir-amjad',
      '/t/khurram-khalil',
      '/t/abou-bakar',
      '/t/naeem-shahzad',
      '/t/muhammad-rizwan',
      '/t/hassan-hafeez',
    ],
    component: Profile,
    isTopbarDark: true,
    isWithoutLayout: true,
  },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
